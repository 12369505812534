import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService, User } from '@auth0/auth0-angular';
import { Browser } from '@capacitor/browser';
import { firstValueFrom, Observable, of, take } from 'rxjs';
import { CreateUserDto, UserResponseDto } from '../../../../aok-backend/src/user/dto/create-user.dto';
import { env } from '../../environments/environment';
import { PreferencesService } from './preferences.service';
import { UserService } from './user/user.service';
import { GetStreamService } from './get-stream/get-stream.service';

// noinspection JSIgnoredPromiseFromCall
@Injectable({
  providedIn: 'root',
})
export class AokAuthService {
  public user$: Observable<User | null | undefined> = this.auth.user$;
  public aokUser?: UserResponseDto; // TO DO: Create the correct response type.

  constructor(
    private readonly auth: AuthService,
    private readonly router: Router,
    private readonly preferencesService: PreferencesService,
    private readonly userService: UserService,
    private getStreamService: GetStreamService,
  ) {}

  logout() {
    firstValueFrom(
      this.auth.logout({
        logoutParams: {
          returnTo: env.auth0Callback,
        },
        async openUrl(url: string) {
          return Browser.open({ url, windowName: '_self' });
        },
      }),
    );
    this.router.navigate(['/home']);
  }

  login() {
    console.log('--- login() ---')
    return firstValueFrom(
      this.auth.loginWithRedirect({
        async openUrl(url: string) {
          return Browser.open({ url, windowName: '_self' });
        },
        appState: { target: "/success/You're signed in/home" },
      }),
    );
  }

  async initializeAuthState() {
    const user = await firstValueFrom(this.auth.user$);
    if (user) {
      this.aokUser = await firstValueFrom(this.userService.getUserByEmail(user.email!));
      console.log('aokUser: ', this.aokUser);
      if (this.aokUser) {
        this.getStreamService.initStream(this.aokUser, this.aokUser.streamIoToken);
      } else {
        /**
         * Get The Auth Zero User
         */
        const activeUser = await firstValueFrom(this.user$);
        console.log('🚨🚨 activeUser: ', activeUser);

        /**
         * Get the ID Kit User
         */
        const idKitUser = await this.preferencesService.getIsIdVerified();
        console.log('🚨🚨 idKitUser: ', idKitUser);

        /**
         * Create new user after registration
         */
        if (activeUser) {
          const newUser: CreateUserDto = {
            authZeroId: activeUser.sub || '',
            streamIoToken: 'to_come',
            email: activeUser.email || '',
            emailVerified: activeUser.email_verified || false,
            firstName: activeUser.nickname || '',
            lastName: activeUser.name || '',
          };
          this.aokUser = await firstValueFrom(this.userService.createUser(newUser))
          console.log('✅✅ 🚨🚨 aokUser: ', this.aokUser);
        } else {
          console.log('❌❌ 🚨🚨 activeUser ELSE... ');
        }
      }
    }
  }

  async isAuthenticated() {
    const authedUser = await firstValueFrom(this.auth.isAuthenticated$);
    return authedUser;
  }

  getUser() {
    return firstValueFrom(this.auth.user$);
  }

  async isEmailVerified() {
    const user = await firstValueFrom(this.auth.user$);
    return user?.email_verified ?? false;
  }
}
