import { Injectable } from '@angular/core';
import { AokAuthService } from '../aok-auth.service';
import { BehaviorSubject } from 'rxjs';
import { ChannelService, ChatClientService, StreamI18nService } from 'stream-chat-angular';
import { env } from '../../../environments/environment';
import { User } from '@auth0/auth0-angular';

@Injectable({
  providedIn: 'root',
})
export class GetStreamService {
  chatIsReady$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(
    private chatService: ChatClientService,
    private channelService: ChannelService,
    private streamI18nService: StreamI18nService,
  ) {}

  async initStream(aokUser: User, streamIoToken: string) {
    const apiKey = env.stream_api_key;
    const userId = aokUser['id']; // Using the DB ID so as to not share any senetive data outside of our system.
    const userToken = streamIoToken;
    try {
      /**
       * Initialise GetStream Connection
       */

      const streamUser = {
        id: userId,
        name: `${aokUser['firstName']}`,
        // Add any other user properties you need
      };
      const initiated = await this.chatService.init(apiKey, streamUser, userToken);
      console.log('💧💧💧 initiated 💧💧💧: ', initiated);

      if (initiated && initiated.me && !initiated.me.banned) {
        this.chatIsReady$.next(true);
      }
      this.streamI18nService.setTranslation();
    } catch (initStreamErr) {
      console.log('initStreamErr: ', initStreamErr);
    }
  }
}
